export const tag_keys = [
    { key: 'Donor Management', value: 'rgba(244,200,0', minHours: 30 },
    { key: 'Digital Library', value: 'rgba(40,100,100', minHours: 30 },
    { key: 'Meeting', value: 'rgba(200,20,30', minHours: 10 },
    { key: 'Admin', value: 'rgba(10,200,200', minHours: 30 },
    { key: 'Development', value: 'rgba(0,0,0', minHours: 30 },
    { key: 'Planning/Design', value: 'rgba(1,200,100', minHours: 30 },
    { key: 'Learning', value: 'rgba(255,187,202', minHours: 30 },
    { key: 'Mongolia', value: 'rgba(20,30,40', minHours: 10 },
    { key: 'python', value: 'rgba(244,200,0', minHours: 20 },
    { key: 'elastic', value: 'rgba(20,100,0', minHours: 20 },
    { key: 'nodejs', value: 'rgba(200,20,30', minHours: 20 },
    { key: 'r', value: 'rgba(40,100,100', minHours: 20 },
    { key: 'react', value: 'rgba(0,0,0', minHours: 20 },
    { key: 'aws', value: 'rgba(140,100,100', minHours: 20 },
    { key: 'php', value: 'rgba(20,20,220', minHours: 20 },
    { key: 'none', value: 'rgba(200,200,200', minHours: 20 },
    { key: 'mysql', value: 'rgba(60,200,40', minHours: 20 },
    { key: 'api', value: 'rgba(244,200,0', minHours: 40 },
    { key: 'etl', value: 'rgba(10,100,230', minHours: 40 },
    { key: 'documentation', value: 'rgba(200,20,30', minHours: 40 },
    { key: 'iiif', value: 'rgba(40,210,60', minHours: 40 },
    { key: 'reporting', value: 'rgba(0,0,0', minHours: 40 },
    { key: 'server_admin', value: 'rgba(140,100,100', minHours: 40 },
    { key: 'testing', value: 'rgba(20,20,220', minHours: 40 },
    { key: 'wordpress_plugin', value: 'rgba(200,200,200', minHours: 40 },
    { key: 'staff_meeting', value: 'rgba(60,200,40', minHours: 10 },
    { key: 'tech_meeting', value: 'rgba(244,200,0', minHours: 10 },
    { key: 'other_meeting', value: 'rgba(140,100,100', minHours: 10 }
]

export const monthLabels = [
    'November',
    'December',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October'
]
