import { tag_keys } from '../store/statics'
function groupBy(list, keyGetter) {
    const map = new Map()
    list.forEach((item) => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
            map.set(key, [item])
        } else {
            collection.push(item)
        }
    })
    return map
}
let arrangement = [11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export function processData(data, type) {
    let wholeData = []
    Object.values(data).forEach((obj) => {
        if (Array.isArray(obj)) {
            let g = groupBy(obj, (d) => d[type])
            //console.log(type, g)
            g.forEach((d) => {
                let meow = []
                let cumu = 0
                let label = d[0][type]
                let minHours = 30
                let minHoursByTag = tag_keys.find((o) => o.key === label)
                if (minHoursByTag) {
                    minHours = minHoursByTag.minHours
                }

                arrangement.forEach((n) => {
                    let m = d.find((o) => parseInt(o.month) === n)
                    //console.log(m)
                    if (typeof m !== 'undefined') {
                        cumu += parseFloat(m.hours)
                    }
                    meow.push(cumu)
                })
                console.log(label, cumu)

                if (label && cumu > minHours) {
                    //console.log(d[0][type], cumu)
                    wholeData.push({
                        label: label,
                        data: meow
                    })
                }
            })
        }
    })
    return wholeData
}

export function processBubbles(data, type) {
    let wholeData = []
    //console.log(type, data)
    Object.values(data).forEach((arr) => {
        if (Array.isArray(arr)) {
            arr.forEach((obj) => {
                //console.log('bubbles', obj)
                wholeData.push({
                    label: obj.category,
                    data: [
                        {
                            x: obj.x,
                            y: obj.y,
                            hours: obj.hours,
                            category: obj.category
                        }
                    ]
                })
            })
        }
    })
    return { datasets: wholeData }
}
