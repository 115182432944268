import React, { Component } from 'react'
import Papa from 'papaparse'
import '../assets/css/main.css'
import BubbleChart from '../components/BubbleChart'
import LineChart from '../components/LineChart'
//import chartIcon from '../../assets/chart-icon.svg'
import { processData, processBubbles } from '../store/processData'
import { monthLabels } from '../store/statics'

export default class Dashboard extends Component {
    state = {
        data: null,
        btnLabel: 'meetings',
        btns: ['phases', 'stack', 'projects', 'meeting', 'tag', 'bubbles'],
        //average: nationalAverageData,
        labels: monthLabels,
        projects: null,
        stack: null,
        phases: null,
        meeting: null,
        tag: null,
        bubbles: null
    }

    componentDidMount() {
        fetch('data/stats.by.month.project.hours.csv')
            .then((resp) => resp.text())
            .then((data) => {
                Papa.parse(data, {
                    header: true,
                    complete: (results) => {
                        let p = processData(results, 'project')
                        this.setState({
                            projects: p
                        })
                    }
                })
            })
        fetch('data/stats.by.month.phases.hours.csv')
            .then((resp) => resp.text())
            .then((data) => {
                Papa.parse(data, {
                    header: true,
                    complete: (results) => {
                        let p = processData(results, 'phases')
                        this.setState({
                            phases: p
                        })
                    }
                })
            })
        fetch('data/stats.by.month.stack.hours.csv')
            .then((resp) => resp.text())
            .then((data) => {
                Papa.parse(data, {
                    header: true,
                    complete: (results) => {
                        let p = processData(results, 'stack')
                        this.setState({
                            stack: p
                        })
                    }
                })
            })
        fetch('data/stats.by.month.meeting.hours.csv')
            .then((resp) => resp.text())
            .then((data) => {
                Papa.parse(data, {
                    header: true,
                    complete: (results) => {
                        let p = processData(results, 'meeting_tag')
                        this.setState({
                            meeting: p
                        })
                    }
                })
            })
        fetch('data/stats.by.month.tag.hours.csv')
            .then((resp) => resp.text())
            .then((data) => {
                Papa.parse(data, {
                    header: true,
                    complete: (results) => {
                        let p = processData(results, 'main_tag')
                        this.setState({
                            tag: p
                        })
                    }
                })
            })
        fetch('data/bubbles.data.csv')
            .then((resp) => resp.text())
            .then((data) => {
                Papa.parse(data, {
                    header: true,
                    complete: (results) => {
                        let p = processBubbles(results, 'category')
                        this.setState({
                            bubbles: p
                        })
                    }
                })
            })
    }

    handleButtonClick = (e) => {
        const { value } = e.target
        let targetLabel = null,
            targetData = null
        switch (value) {
            case 'projects':
                targetLabel = 'projects'
                targetData = this.state.projects
                break
            case 'stack':
                targetLabel = 'stack'
                targetData = this.state.stack
                break
            case 'phases':
                targetLabel = 'phases'
                targetData = this.state.phases
                break
            case 'meeting':
                targetLabel = 'meeting'
                targetData = this.state.meeting
                break
            case 'tag':
                targetLabel = 'tag'
                targetData = this.state.tag
                break
            case 'bubbles':
                targetLabel = 'bubbles'
                targetData = this.state.bubbles
                break
            default:
                break
        }

        this.setState({
            data: targetData,
            btnLabel: targetLabel
        })
    }

    createBtns = () => {
        let b = []
        //console.log(classes.btns)
        this.state.btns.forEach((btn, idx) => {
            b.push(
                <button
                    key={idx}
                    className='buttonClass'
                    value={btn}
                    onClick={this.handleButtonClick}
                >
                    {btn.toUpperCase()}
                </button>
            )
        })
        return b
    }

    setChartType = (l) => {
        const { data, labels } = this.state
        //return <LineChart labels={labels} data={data} />
        if (l === 'bubbles') {
            return <BubbleChart data={data} />
        } else {
            return <LineChart labels={labels} data={data} />
        }
    }

    render() {
        let { btnLabel } = this.state
        let btns = this.createBtns()
        let chartType = this.setChartType(btnLabel)
        //console.log('bubbles', this.state.bubbles)
        return (
            <div>
                {btns}
                {chartType}
            </div>
        )
    }
}
