import React, { Component } from 'react'
import Chart from 'chart.js'
import classes from '../assets/css/main.css'
import { tag_keys } from '../store/statics'
let myLineChart

//--Chart Style Options--//
Chart.defaults.global.defaultFontFamily = "'Roboto Mono', sans-serif"
Chart.defaults.global.legend.display = true
//--Chart Style Options--//

function getLineColor(ctx) {
    //console.log('getlinecolor', ctx.dataset.label)
    const value = ctx.dataset.label
    let c = tag_keys.find((c) => c.key === value)
    if (c) {
        return `${c.value}, 1)`
    } else {
        return 'rgba(222, 22, 11, 1)'
    }

    //return Utils.color(ctx.datasetIndex);
}

export default class LineChart extends Component {
    chartRef = React.createRef()

    componentDidMount() {
        this.buildChart()
    }

    componentDidUpdate() {
        this.buildChart()
    }

    buildChart() {
        const { data, labels } = this.props
        const ctx = this.chartRef.current.getContext('2d')
        if (typeof myLineChart !== 'undefined') myLineChart.destroy()
        //console.log(ctx)

        myLineChart = new Chart(ctx, {
            type: 'line',
            data: {
                //Bring in data
                labels: labels,
                datasets: data //[{ data }, thresholdHighArray]
            },
            options: {
                scales: {
                    yAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Cumulative Hours'
                            },
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 100
                            }
                        }
                    ],
                    xAxes: [
                        {
                            display: true,

                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 100
                            }
                        }
                    ]
                },
                plugins: {
                    legend: {
                        title: {
                            display: true,
                            text: 'Legend Title'
                        }
                    },
                    tooltip: true
                },
                elements: {
                    line: {
                        fill: false,
                        backgroundColor: getLineColor,
                        borderColor: getLineColor
                    },
                    point: {
                        radius: 4,
                        //backgroundColor: getLineColor,
                        borderColor: getLineColor
                    }
                }
            }
        })
    }
    render() {
        return (
            <div className={classes.graphContainer}>
                <canvas id='myChart' ref={this.chartRef} />
            </div>
        )
    }
}
