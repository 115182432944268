import React, { Component } from 'react'
import Dashboard from './views/Dashboard'

class App extends Component {
    render() {
        return <Dashboard />
    }
}

export default App
