import React, { Component } from 'react'
import Chart from 'chart.js'
import classes from '../assets/css/main.css'
//import { tag_keys } from '../store/statics'
let myBubbleChart

//--Chart Style Options--//
Chart.defaults.global.defaultFontFamily = "'Roboto Mono', sans-serif"
Chart.defaults.global.legend.display = true
//--Chart Style Options--//

// function getBubbleColor(ctx) {
//     //console.log('getlinecolor', ctx.dataset.label)
//     const value = ctx.dataset.label
//     let c = tag_keys.find((c) => c.key === value)
//     if (c) {
//         return `${c.value}, 0.5)`
//     } else {
//         return 'rgba(222, 22, 11, 0.5)'
//     }

//     //return Utils.color(ctx.datasetIndex);
// }
// function colorBasedOnCategory(opaque, context) {
//     const obj = context.dataset.data[0]
//     const category = obj.category
//     if (category === 'api') {
//         return 'rgba(244,200,100,1)'
//     } else {
//         return 'rgba(100,200, 244, 0.5)'
//     }
// }

export default class BubbleChart extends Component {
    chartRef = React.createRef()

    componentDidMount() {
        this.buildChart()
    }

    componentDidUpdate() {
        this.buildChart()
    }

    buildChart() {
        const { data } = this.props
        const ctx = this.chartRef.current.getContext('2d')
        if (typeof myBubbleChart !== 'undefined') myBubbleChart.destroy()
        //console.log(ctx)

        myBubbleChart = new Chart(ctx, {
            type: 'bubble',

            data: data,

            options: {
                aspectRatio: 2,
                maintainAspectRatio: true,
                responsive: true,
                plugins: {
                    legend: false,
                    tooltip: false,
                    datalabels: {
                        color: '#36A2EB'
                    }
                },
                scaleShowValues: true,
                scales: {
                    yAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Enjoyment'
                            },
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 100
                            },
                            gridLines: {
                                color: [
                                    'rgba(22, 22, 22, 0.2)',
                                    'rgba(22, 22, 22, 0.2)',
                                    'rgba(22, 22, 22, 0.2)',
                                    'rgba(2, 22, 22, 0.2)',
                                    'rgba(22, 22, 22, 0.2)',
                                    'rgba(0, 0, 0, 1)',
                                    'rgba(22, 22, 22, 0.2)',
                                    'rgba(22, 22, 22, 0.2)',
                                    'rgba(22, 22, 22, 0.2)',
                                    'rgba(22, 22, 22, 0.2)'
                                ]
                            }
                        }
                    ],
                    xAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Skill Level'
                            },
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 100
                            },
                            gridLines: {
                                color: [
                                    'rgba(22, 22, 22, 0.2)',
                                    'rgba(22, 22, 22, 0.2)',
                                    'rgba(22, 22, 22, 0.2)',
                                    'rgba(2, 22, 22, 0.2)',
                                    'rgba(22, 22, 22, 0.2)',
                                    'rgba(0, 0, 0, 1)',
                                    'rgba(22, 22, 22, 0.2)',
                                    'rgba(22, 22, 22, 0.2)',
                                    'rgba(22, 22, 22, 0.2)',
                                    'rgba(22, 22, 22, 0.2)'
                                ]
                            }
                        }
                    ]
                },
                elements: {
                    point: {
                        //backgroundColor: colorBasedOnCategory.bind(null, true),
                        //backgroundColor: 'rgba(244,200,0, 0.4)', //getBubbleColor,
                        //borderColor: colorBasedOnCategory.bind(null, true),
                        backgroundColor: function (context) {
                            const obj = context.dataset.data[0]
                            const base = Math.abs(obj.x) * 2.5
                            return `rgba(244, ${base}, 0, 0.4)`
                        },
                        //'rgba(244,200,0, 0.9)', //getBubbleColor,
                        borderColor: function (context) {
                            const obj = context.dataset.data[0]
                            const base = Math.abs(obj.x) * 2.5
                            return `rgba(244, ${base}, 0, 0.9)`
                        },
                        borderWidth: function (context) {
                            return Math.min(
                                Math.max(1, context.datasetIndex + 1),
                                8
                            )
                        },

                        hoverBackgroundColor: 'transparent',

                        // hoverBorderColor: function (context) {
                        //     return Utils.color(context.datasetIndex)
                        // },

                        hoverBorderWidth: function (context) {
                            //console.log(context.dataset.data[0].v)
                            const obj = context.dataset.data[0]
                            return Math.round((8 * obj.hours) / 1000)
                        },

                        radius: function (context) {
                            const size = context.chart.width
                            const obj = context.dataset.data[0]
                            //console.log('radius', obj, size)
                            const base = Math.abs(obj.hours) / 300
                            return (size / 24) * base
                        }
                    }
                }
            }
        })
    }
    render() {
        return (
            <div className={classes.graphContainer}>
                <canvas id='myChart' ref={this.chartRef} />
            </div>
        )
    }
}
